import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Axios from "axios";

import "../styles/form.scss";
import "../styles/footer.scss";
import "../styles/rules.scss";

const Rules = () => {

	return (
		<>
		<section className="rules">
			<div className="container" style={{flexDirection: 'column'}}>
				<p>
				Na temelju članka 69. Zakona o igrama na sreću (NN 87/09, 35/13, 158/13, 41/14 i 143/14) i
				Pravilnika o priređivanju nagradnih igara (NN 8/10) ReRoot d.o.o., Ribarska 4, Osijek, OIB
				53340601457, donosi sljedeća
				</p>
				<h3>
				PRAVILA NAGRADNE IGRE
				</h3>
				<h2>
				„Dove MEN+CARE nagradna igra“
				</h2>
				<h3>
				Članak 1. Priređivač
				</h3>
				<p>
				Priređivač nagradne igre je ReRoot d.o.o., Ribarska 4, Osijek, OIB 53340601457.
				</p>
				<h3>
				Članak 2. Trajanje i svrha nagradne igre

				</h3>
				<p>
				
				Nagradna igra se provodi od 1. 3. 2022. do 31. 3. 2022. na području Republike Hrvatske.
				</p>
				<h3>
				Članak 3. Svrha nagradne igre

				</h3>
				<p>
				
				Nagradna igra se priređuje u cilju promidžbe proizvoda robnih marke Dove i Dove
				MEN+CARE.
				</p>
				<h3>
				Članak 4. Način sudjelovanja u nagradnoj igri

				</h3>
				<p>
				
				U nagradnoj igri mogu sudjelovati sve fizičke osobe koje za vrijeme trajanja nagradne igre na
				bilo kojem prodajnom mjestu u Republici Hrvatskoj na istom računu naprave kupnju
				dva (2) Dove MEN+CARE proizvoda
				<br/>
				ili
				<br/>
				jedan (1) Dove MEN+CARE proizvod i jedan (1) Dove proizvod iz bilo koje linije.
				Sudjelovanje se ostvaruje na jedan od načina:
				- Slanjem SMS-a sadržaja: DANOCEVA, broj računa, ime i prezime, adresa (ulica i kućni
				broj, poštanski broj i mjesto) na broj 60777. Cijena SMS poruke je 2,40 kn, PDV uključen.
				<br/>
				ili
				<br/>
				- Ispunjavanjem obrasca na www.danoceva.dovemencare.hr (potrebno je upisati broj
				računa, ime i prezime, ulicu i kućni broj, poštanski broj i mjesto i kontakt broj mobitela).
				Originalni račun potrebno je sačuvati do kraja nagradne igre te na zahtjev Priređivača
				predočiti pri podizanju nagrade. Svaki sudionik može sudjelovati u nagradnoj igri
				neograničen broj puta s različitim brojevima računa. Svaki pojedini broj računa može biti
				iskorišten samo jedanput. U slučaju da isti broj računa bude ponovno poslan od strane iste
				ili druge osobe ta će prijava biti proglašena nevažećom.
				</p>
				<p>
				
				Duljina SMS poruke ograničena je na 160, odnosno 70 znakova u slučaju korištenja
				dijakritičkih znakova (npr. č, ć, đ, š, ž). Poruka čija duljina prelazi navedena ograničenja,
				prelazi u drugu poruku te će kao takva biti naplaćena od strane mobilnog operatera. Zbog
				navedenoga, preporuka je ne koristiti posebne znakove u tekstu SMS poruke.</p>
				<p>
				
				Maloljetne osobe mogu u nagradnoj igri sudjelovati samo uz suglasnost roditelja ili skrbnika.
				</p>
				<h3>
				Članak 5. Pravo sudjelovanja u nagradnoj igri
				</h3>
				<p>
				
				U nagradnoj igri ne mogu sudjelovati zaposlenici Priređivača, zaposlenici tvrtki Unilever
				Hrvatska d.o.o., New Level d.o.o., Atlantic Grupa d.d. niti članovi njihove uže obitelji.
				</p>
				<h3>
				Članak 6. Nagradni fond
				</h3>
				<p>
				
				Nagradni fond sastoji se od:</p>
				<ul>
				<li>
				<p>
				1 x Sedmodnevno obiteljsko ljetovanje u FAMILY HOTELU LIŠANJ 4* (dvije odrasle osobe
				i dvoje djece, all inclusive light), vrijednost nagrade 27.850,00 kuna
				</p>
				</li>
				<li><p>2 x PlayStation 5, vrijednost nagrade 7.299,00 kuna</p>
				</li><li><p>30 x Dove poklon paket, vrijednost nagrade 245,18 kuna
				Ukupna vrijednost nagradnog fonda iznosi 49.803,40 kuna. Priređivač se obvezuje uplatiti
				5% ukupne vrijednosti nagradnog fonda u korist Crvenog križa Republike Hrvatske.
				Fotografije nagrada na promotivnim materijalima su simbolične.</p>
				</li>
				</ul>
				<h3>
				Članak 7. Izvlačenje i objava dobitnika
				</h3>
				<p>
				
				Izvlačenje dobitnika nagrada održat će se 1. 4. 2022.</p>
				<p>
				Izvlačenje dobitnika će biti provedeno uz prisustvo tročlane komisije. Dobitnici će biti
				izvučeni putem računalne aplikacije, metodom slučajnog odabira.</p>
				<p>
				Jedna osoba može osvojiti samo jednu nagradu.</p>
				<p>
				Komisija će provjeriti je li dobitnik sudjelovao u nagradnoj igri sukladno ovim Pravilima. U
				slučaju da se njegova prijava proglasi nevažećom, nagrada će biti podijeljena sljedećem
				izvučenom sudioniku koji ima pravo na nagradu.</p>
				<p>
				Imena svih dobitnika bit će objavljena u roku od 8 dana od izvlačenja na internet stranici
				www.danoceva.dovemencare.hr.
				</p>
				<h3>
				Članak 8. Preuzimanje nagrada
				</h3>
				<p>
				
				Nagrada se ne može zamijeniti za novac. Preuzimanjem nagrade prestaju sve daljnje obveze
				Priređivača nagradne igre prema dobitniku. Dobitnici će o dobitku biti obaviješteni u roku od
				8 dana od izvlačenja pri čemu će dobiti informaciju o načinu preuzimanja nagrade. Dobitnici
				moraju preuzeti nagradu u roku od 30 dana od dana primitka obavijesti o dobivenoj nagradi,
				uz predočenje identifikacijske isprave.</p><p>
				
				Ako dobitnici nagrade ne podignu u tom roku, bit će ponovno obaviješteni i nagrade će moći
				podignuti u roku od 15 dana od dana primitka dodatne obavijesti. Ako dobitnik ne može
				osobno preuzeti svoju nagradu može je preuzeti od dobitnika ovlaštena osoba, također uz
				predočenje identifikacijske isprave. Ako je dobitnik maloljetna osoba, nagradu mogu, uz
				predloženu odgovarajuću dokumentaciju u njegovo ime preuzeti njegovi roditelji ili skrbnici.</p><p>
				
				Sudjelovanjem u ovoj nagradnoj igri sudionici se slažu da, ako postanu dobitnici nagrada,
				njihove osobne podatke (ime i prezime, adresu) Priređivač može koristiti u svrhu
				upoznavanja javnosti s rezultatima nagradne igre bez naknade i dodatne suglasnosti
				dobitnika te da se oni mogu objaviti u tiskanom, zvučnom, slikovnom i video materijalu.
				U slučaju da se u nagradnu igru uključi manje sudionika od broja nagrada koje su predviđene
				nagradnim fondom, dodijelit će se onoliko nagrada koliko je u nagradnoj igri sudjelovalo
				sudionika. S nagradama koje ne budu realizirane postupit će se u skladu sa Zakonom o
				igrama na sreću (NN 87/09, 35/13, 158/13, 41/14 i 143/14) i Pravilnikom o priređivanju
				nagradnih igara (NN 8/10).
				</p>
				<h3>
				Članak 9. Ostale odredbe

				</h3>
				<p>
				
				Priređivač ne odgovara za nepotpune, neispravne ili zakašnjele prijave. Priređivač i osobe
				koje sudjeluju u provođenju nagradne igre ne odgovaraju za moguću štetu, koja bi mogla
				proizlaziti iz nagrada ili pogodnosti.</p><p>
				
				Nagradna igra može se prekinuti samo u slučaju da nastupe okolnosti za koje Priređivač nije
				odgovoran, odnosno koje nije mogao spriječiti, otkloniti ili izbjeći. Sudionici će o prekidu
				nagradne igre biti obaviješteni na internet stranici www.danoceva.dovemencare.hr.
				Priređivač u tom slučaju sudionicima ne odgovara za moguću štetu.</p><p>
				
				Odluka Priređivača o svim pitanjima vezanim uz nagradnu igru odnosno primjenu ovih
				pravila konačna je i obvezuje sve sudionike.
				</p>
				<h3>
				Članak 10. Objava pravila

				</h3>
				<p>
				
				Pravila nagradne igre uz navođenje klase, urudžbenog broja i datuma izdavanja suglasnosti
				Ministarstva financija bit će objavljena na internet stranici www.danoceva.dovemencare.hr
				prije početka nagradne igre.
				</p>
				<h3>
				Članak 11. Korištenje osobnih podataka

				</h3>
				<p>
				
				Sudionici u nagradnoj igri sudjelovanjem, odnosno slanjem SMS poruke, očituju svoje
				prihvaćanje ovih Pravila te bez daljnjeg pitanja i odobrenja ovlašćuju Priređivača da kao
				Voditelj obrade osobnih podataka prikuplja i obrađuje navedene osobne podatke u svrhu
				provođenja nagradne igre. Izvršitelj obrade osobnih podataka je New Level d.o.o.,
				Badalićeva 31, Zagreb, OIB 99200431992.</p><p>
				
				Sudionik može u bilo kojem trenutku povući svoju privolu za obradu osobnih podataka
				dopisom na adresu Priređivača. Osobni podaci sudionika nagradne igre čuvat će se 120 dana
				nakon završetka nagradne igre nakon čega će biti uništeni, osim osobnih podataka
				potrebnih za objavu dobitnika nagradne igre i osobnih podataka upisanih u Zapisnik o
				izvlačenju dobitnika. Osobni podaci dobitnika mogu se podijeliti s pružateljem poštanskih
				usluga ili usluga kurirske dostave u svrhu dostave nagrada.</p><p>
				
				Priređivač će poduzeti sve razumno potrebne mjere da bi osigurao da se osobni podaci
				sudionika u nagradnoj igri obrađuju i koriste na siguran način i u suglasnosti s primjenjivim
				zakonodavstvom.
				</p>

				<h3>
				Članak 12. Rješavanje pritužbi i valjanost pravila
				</h3>
				<p>
				
				Sve pritužbe i reklamacije rješava Priređivač. U slučaju osnovanih pritužbi Priređivač se
				obvezuje u što kraćem vremenu ispraviti nedostatke i o tome obavijestiti sudionika odnosno
				ako je to potrebno i ostale sudionike.</p><p>
				
				U slučaju spora između Priređivača i sudionika ove nagradne igre nadležan je Općinski
				građanski sud u Zagrebu.
				</p>
			</div>
		</section>
		<section className="form" style={{display: 'flex'}}>
			<Link to="https://www.unilever.com/" className="logo_container" style={{margin: '200px auto -140px'}}>
				<StaticImage
					src="../images/logo_footer.svg"
					alt="Dove"
					placeholder="transparent"
					className="logo"
					quality={100}
				/>
			</Link>
		</section>
		</>
	);
};

export default Rules;
