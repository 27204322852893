import * as React from "react";
import Header from "../components/header.jsx";
import Hero from "../components/hero.jsx";
import Rules from "../components/rules.jsx";
import Footer from "../components/footer.jsx";

const IndexPage = () => {
  return (
    <main>
      <title>Pravila nagradne igre</title>
      <Header />
      <Rules />
    </main>
  );
};

export default IndexPage;
